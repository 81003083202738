import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Carol Espinaza', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Carol Espinaza',
  name: 'Carol Espinaza',
  subtitle: 'Constituyente',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'acerca-de-carol.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'seguridad.jpg',
    title: 'SEGURIDAD',
    info:
      'Será nuestra prioridad comunal el primer año.  Inmediatamente se implementará un teléfono de denuncia anónima, se gestionará una central de monitoreo en comunicación directa con las  policías.   Además, la instalación de 4 oficinas de seguridad comunal integrada con cámaras y funcionarios, motos y camionetas para atender más rápidamente a los vecinos.',
    info2:
      'Se gestionará un globo aerostático de televigilancia en la zona del 14 de Vicuña Mackenna y se entregarán a los vecinos kit de seguridad anti-portonazo, entre varias otras medidas. ',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'vivienda.jpg',
    title: 'VIVIENDA',
    info:
      'Revisión del plano regulador que data del 2017. Mejoramiento de las viviendas sociales antiguas y edificios.',
    info2:
      'Diseñar, construir y proponer subsidios para 3er piso.  Acompañamiento a los vecinos de los 91 comités y de las tomas para buscar en coordinación y gestión con Minvu la mejor solución para ellos. Implementar más paneles sociales.',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'salud.webp',
    title: 'SALUD',
    info: 'Se fortalecen programas sociales de médico a domicilio y doctor en tu barrio.',
    info2:
      'Construir otro Cesfam en la comuna. Mejorar el abastecimiento de remedios en los consultorios.  Mejorar el trato de los funcionarios a los vecinos.  Fiscalizar el cumplimiento de atención de los médicos e incorporar en colaboración con privados la incorporación de especialidades.',
    url: 'mujer.webp',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'adulto-mayor.jpg',
    title: 'MEJOR CALIDAD DE VIDA DE LOS VECINOS',
    info:
      'Más remedios en los consultorios, mejoramiento en la infraestructura de colegios, jornadas para emprendedores y ferias laborales; apoyo y acompañamiento a los adultos mayores, pero por sobre todo austeridad.',
    info2:
      'Destinar correctamente los recursos para invertir en deporte, cultura en jóvenes y niños; mejorar iluminación y recuperación de áreas verdes entre otras muchas medidas.',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: 'Envíanos un email',
  email: 'carol.laflorida2024@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'whatsapp',
      url: 'tel:+56 974 086 059',
    },
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/carolespinaza12',
    },
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/carolespinaza12',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/carolespinaza12/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
